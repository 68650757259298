export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';

export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILED = 'REGISTER_FAILED';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILED = 'LOGOUT_FAILED';

export const ALL_FORM_FETCH_REQUEST = 'ALL_FORM_FETCH_REQUEST';
export const ALL_FORM_FETCH_SUCCESS = 'ALL_FORM_FETCH_SUCCESS';
export const ALL_FORM_FETCH_FAILED = 'ALL_FORM_FETCH_FAILED';

export const FORM_CREATE_REQUEST = 'FORM_CREATE_REQUEST';
export const FORM_CREATE_SUCCESS = 'FORM_CREATE_SUCCESS';
export const FORM_CREATE_FAILED = 'FORM_CREATE_FAILED';

export const FORM_UPDATE_REQUEST = 'FORM_UPDATE_REQUEST';
export const FORM_UPDATE_SUCCESS = 'FORM_UPDATE_SUCCESS';
export const FORM_UPDATE_FAILED = 'FORM_UPDATE_FAILED';

export const FORM_DELETE_REQUEST = 'FORM_DELETE_REQUEST';
export const FORM_DELETE_SUCCESS = 'FORM_DELETE_SUCCESS';
export const FORM_DELETE_FAILED = 'FORM_DELETE_FAILED';

export const FORM_FETCH_REQUEST = 'FORM_FETCH_REQUEST';
export const FORM_FETCH_SUCCESS = 'FORM_FETCH_SUCCESS';
export const FORM_FETCH_FAILED = 'FORM_FETCH_FAILED';

export const SECTION_CREATE_REQUEST = 'SECTION_CREATE_REQUEST';
export const SECTION_CREATE_SUCCESS = 'SECTION_CREATE_SUCCESS';
export const SECTION_CREATE_FAILED = 'SECTION_CREATE_FAILED';

export const SECTION_FETCH_REQUEST = 'SECTION_FETCH_REQUEST';
export const SECTION_FETCH_SUCCESS = 'SECTION_FETCH_SUCCESS';
export const SECTION_FETCH_FAILED = 'SECTION_FETCH_FAILED';

export const SECTION_DELETE_REQUEST = 'SECTION_DELETE_REQUEST';
export const SECTION_DELETE_SUCCESS = 'SECTION_DELETE_SUCCESS';
export const SECTION_DELETE_FAILED = 'SECTION_DELETE_FAILED';

export const SECTION_UPDATE_REQUEST = 'SECTION_UPDATE_REQUEST';
export const SECTION_UPDATE_SUCCESS = 'SECTION_UPDATE_SUCCESS';
export const SECTION_UPDATE_FAILED = 'SECTION_UPDATE_FAILED';

export const QUESTION_CREATE_REQUEST = 'QUESTION_CREATE_REQUEST';
export const QUESTION_CREATE_SUCCESS = 'QUESTION_CREATE_SUCCESS';
export const QUESTION_CREATE_FAILED = 'QUESTION_CREATE_FAILED';

export const QUESTION_FETCH_REQUEST = 'QUESTION_FETCH_REQUEST';
export const QUESTION_FETCH_SUCCESS = 'QUESTION_FETCH_SUCCESS';
export const QUESTION_FETCH_FAILED = 'QUESTION_FETCH_FAILED';

export const QUESTION_UPDATE_REQUEST = 'QUESTION_UPDATE_REQUEST';
export const QUESTION_UPDATE_SUCCESS = 'QUESTION_UPDATE_SUCCESS';
export const QUESTION_UPDATE_FAILED = 'QUESTION_UPDATE_FAILED';

export const QUESTION_DETAILS_UPDATE_REQUEST =
	'QUESTION_DETAILS_UPDATE_REQUEST';
export const QUESTION_DETAILS_UPDATE_SUCCESS =
	'QUESTION_DETAILS_UPDATE_SUCCESS';
export const QUESTION_DETAILS_UPDATE_FAILED = 'QUESTION_DETAILS_UPDATE_FAILED';

export const QUESTION_DELETE_REQUEST = 'QUESTION_DELETE_REQUEST';
export const QUESTION_DELETE_SUCCESS = 'QUESTION_DELETE_SUCCESS';
export const QUESTION_DELETE_FAILED = 'QUESTION_DELETE_FAILED';

export const UPLOAD_REQUEST = 'UPLOAD_REQUEST';
export const UPLOAD_SUCCESS = 'UPLOAD_SUCCESS';
export const UPLOAD_FAILED = 'UPLOAD_FAILED';

export const OPTION_CREATE_REQUEST = 'OPTION_CREATE_REQUEST';
export const OPTION_CREATE_SUCCESS = 'OPTION_CREATE_SUCCESS';
export const OPTION_CREATE_FAILED = 'OPTION_CREATE_FAILED';

export const OPTION_FETCH_REQUEST = 'OPTION_FETCH_REQUEST';
export const OPTION_FETCH_SUCCESS = 'OPTION_FETCH_SUCCESS';
export const OPTION_FETCH_FAILED = 'OPTION_FETCH_FAILED';

export const OPTION_UPDATE_REQUEST = 'OPTION_UPDATE_REQUEST';
export const OPTION_UPDATE_SUCCESS = 'OPTION_UPDATE_SUCCESS';
export const OPTION_UPDATE_FAILED = 'OPTION_UPDATE_FAILED';

export const OPTION_DELETE_REQUEST = 'OPTION_DELETE_REQUEST';
export const OPTION_DELETE_SUCCESS = 'OPTION_DELETE_SUCCESS';
export const OPTION_DELETE_FAILED = 'OPTION_DELETE_FAILED';

export const RESPONSE_FETCH_REQUEST = 'RESPONSE_FETCH_REQUEST';
export const RESPONSE_FETCH_SUCCESS = 'RESPONSE_FETCH_SUCCESS';
export const RESPONSE_FETCH_FAILED = 'RESPONSE_FETCH_FAILED';

export const RESPONSE_LOCAL_UPDATE_SUCCESS = 'RESPONSE_LOCAL_UPDATE_SUCCESS';
export const RESPONSE_LOCAL_CLEAR_SUCCESS = 'RESPONSE_LOCAL_CLEAR_SUCCESS';

export const RESPONSE_CREATE_REQUEST = 'RESPONSE_CREATE_REQUEST';
export const RESPONSE_CREATE_SUCCESS = 'RESPONSE_CREATE_SUCCESS';
export const RESPONSE_CREATE_FAILED = 'RESPONSE_CREATE_FAILED';

export const USER_FETCH_REQUEST = 'USER_FETCH_REQUEST';
export const USER_FETCH_SUCCESS = 'USER_FETCH_SUCCESS';
export const USER_FETCH_FAILED = 'USER_FETCH_FAILED';

export const RESPONSE_EXCEL_CREATE_REQUEST = 'RESPONSE_EXCEL_CREATE_REQUEST';
export const RESPONSE_EXCEL_CREATE_SUCCESS = 'RESPONSE_EXCEL_CREATE_SUCCESS';
export const RESPONSE_EXCEL_CREATE_FAILED = 'RESPONSE_EXCEL_CREATE_FAILED';
